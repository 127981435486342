import { Route, Routes } from "react-router-dom";

import { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import ScreenLoading from "./components/ScreenLoading/ScreenLoading";
// great då
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const Login = lazy(() => import("./pages/Login/Login"));
const StoreDetails = lazy(() => import("./pages/StoreDetails/StoreDetails"));
const Documents = lazy(() => import("./pages/Documents/Documents"));
const BankDetails = lazy(() => import("./pages/BankDetails/BankDetails"));
const VatDetails = lazy(() => import("./pages/VatDetails/VatDetails"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const YourShop = lazy(() => import("./pages/YourShop/YourShop"));
const Locations = lazy(() => import("./pages/Locations/Locations"));
const SetDelivery = lazy(() => import("./pages/SetDelivery/SetDelivery"));
const AddProduct = lazy(() => import("./pages/AddProduct/AddProduct"));
const Products = lazy(() => import("./pages/Products/Products"));
const Discounts = lazy(() => import("./pages/Discounts/Discounts"));
const YourOrders = lazy(() => import("./pages/YourOrders/YourOrders"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const OrderStats = lazy(() => import("./pages/OrderStats/OrderStats"));
const Financials = lazy(() => import("./pages/Financials/Financials"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const About = lazy(() => import("./pages/About/About"));
const ManageInventory = lazy(() =>
  import("./pages/ManageInventory/ManageInventory")
);

const OnboardLayout = lazy(() =>
  import("./Layouts/OnboardLayout/OnboardLayout")
);
const AppLayout = lazy(() => import("./Layouts/AppLayout/AppLayout"));
function App() {
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   uploadFile(newFile)
  //     .then((res) => {
  //       console.log(res);
  //       setLoading(false);
  //       alert("Uploaded");
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //       alert("not uploaded");
  //     });
  // }, []);
  return (
    <>
      <Toaster />
      <div className="min-w-[320px]">
        <Suspense fallback={fb()}>
          <Routes>
            <Route path="/" element={<OnboardLayout />}>
              <Route index element={<SignUp />} />
              <Route path="login" element={<Login />} />
              <Route path="store-details" element={<StoreDetails />} />
              <Route path="documents" element={<Documents />} />
              <Route
                path="bank-details"
                element={<BankDetails shop={false} />}
              />
              <Route path="vat-details" element={<VatDetails />} />
            </Route>
            <Route path="/dashboard" element={<AppLayout />}>
              <Route index element={<Dashboard />} />
              {/* Your Shop */}
              <Route path="your-shop" element={<YourShop />} />
              <Route
                path="your-shop/pickup-locations"
                element={<Locations />}
              />
              <Route
                path="your-shop/bank-details"
                element={<BankDetails shop={true} contain={true} />}
              />
              <Route
                path="your-shop/setup-delivery"
                element={<SetDelivery />}
              />
              <Route path="your-shop/add-product" element={<AddProduct />} />
              <Route
                path="your-shop/manage-inventory"
                element={<ManageInventory />}
              />
              {/* Products */}
              <Route path="products" element={<Products />} />
              <Route
                path="products/manage-inventory"
                element={<ManageInventory />}
              />
              {/* Discounts */}
              <Route path="discounts" element={<Discounts />} />
              {/* Your Orders */}
              <Route path="your-orders" element={<YourOrders />} />
              {/* Financials */}
              <Route path="financials" element={<Financials />} />

              {/* Privacy Policy */}
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="about" element={<About />} />

              {/* Profile */}
              <Route path="profile" element={<Profile />}>
                <Route
                  path="personal"
                  element={<SignUp header={false} shop={true} />}
                />
                <Route
                  path="store-details"
                  element={<StoreDetails header={false} shop={true} />}
                />
                <Route
                  path="documents"
                  element={<Documents header={false} shop={true} />}
                />
                <Route
                  path="bank-details"
                  element={<BankDetails header={false} shop={true} />}
                />
                <Route
                  path="vat-details"
                  element={<VatDetails header={false} shop={true} />}
                />
              </Route>
              {/* Order Stats/Sales */}
              <Route path="order-stats" element={<OrderStats />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </>
  );
}

function fb() {
  return (
    // <h1 className="flex justify-center items-center text-2xl">Loading...</h1>
    <ScreenLoading />
  );
}

export default App;
