import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const allProductsSlice = createSlice({
  name: "allProducts",
  initialState,
  reducers: {
    set_products: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { set_products } = allProductsSlice.actions;
export default allProductsSlice.reducer;
