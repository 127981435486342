import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

const dashboardMenuSlice = createSlice({
  name: "dashboardMenu",
  initialState,
  reducers: {
    close_menu: (state) => {
      state.value = false;
    },
    open_menu: (state) => {
      state.value = true;
    },
  },
});

export const { open_menu, close_menu } = dashboardMenuSlice.actions;
export default dashboardMenuSlice.reducer;
