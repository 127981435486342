import { createSlice } from "@reduxjs/toolkit";

const super_market_data = JSON.parse(localStorage.getItem("flow_super_market"));
// console.log("super_market_data", super_market_data);

const initialState = {
  super_market_id: super_market_data?.super_market_id || null,
};

const supermarketSlice = createSlice({
  name: "super_market",
  initialState,
  reducers: {
    set_super_market_id: (state, action) => {
      state.super_market_id = action.payload;
      localStorage.setItem("flow_super_market", JSON.stringify(state));
    },
  },
});

export const { set_super_market_id } = supermarketSlice.actions;
export default supermarketSlice.reducer;
