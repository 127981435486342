import { createSlice } from "@reduxjs/toolkit";

const current_product =
  JSON.parse(localStorage.getItem("current_product"))?.product || null;

const product_schema = {
  productImages: [],
};

const initialState = {
  product: current_product || product_schema,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    add_image: (state, action) => {
      state.product.productImages.push({ image_url: action.payload });
      localStorage.setItem("current_product", JSON.stringify(state));
    },
    remove_image: (state, action) => {
      state.product.productImages = state.product.productImages.filter(
        (item) => item.image_url !== action.payload
      );
      localStorage.setItem("current_product", JSON.stringify(state));
    },
    clear_current_product: (state) => {
      state.product.productImages = [];
      localStorage.removeItem("current_product");
    },
  },
});

export const { add_image, remove_image, clear_current_product } =
  productSlice.actions;
export default productSlice.reducer;
