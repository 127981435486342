import { createSlice } from "@reduxjs/toolkit";

const orders_data = JSON.parse(localStorage.getItem("flow_orders"));

const initialState = {
  orders: orders_data?.orders || null,
  ordered_items: orders_data?.ordered_items || null,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    set_orders: (state, action) => {
      state.orders = action.payload;
      console.log("immediate acces of state: ", state.orders);
      // to extract individual products
      const items = state.orders.flatMap((order) => {
        const order_id = order.id;
        const order_date = order.created_at;
        const user_id = order.user.id;
        const user_name = order.user.name;
        const user_address = order.user.address;
        const user_email = order.user.email_id;

        const cart_id = order.cart.id;

        return order.cart.cart_items.map((item) => {
          const product_id = item.product_id;
          const count = item.count;
          const product_price = item.product.price;
          const product_images = item.product.image_urls;
          const amount = count * product_price;
          const name = item.product.name;

          return {
            name,
            order_id,
            order_date,
            user_id,
            user_name,
            user_address,
            user_email,
            cart_id,
            product_id,
            count,
            amount,
            product_price,
            product_images,
          };
        });
      });

      state.ordered_items = [...items];
      localStorage.setItem("flow_orders", JSON.stringify(state));
    },
  },
});

export const { set_orders } = ordersSlice.actions;
export default ordersSlice.reducer;
