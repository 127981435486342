import React from "react";

function ScreenLoading() {
  return (
    <div className="flex items-center justify-center fixed top-0 left-0 w-full h-screen bg-[rgba(255,255,255,0.1)]">
      <span className="loading loading-dots loading-lg"></span>
    </div>
  );
}

export default ScreenLoading;
