import { configureStore } from "@reduxjs/toolkit";

import dashbordMenuReducer from "./dashboardMenuSlice";
import authReducer from "./authSlice";
import productReducer from "./productSlice";
import allProductsReducer from "./allProductsSlice";
import supermarketReducer from "./supermarketSlice";
import ordersReducer from "./ordersSlice";
import vendorProfileReducer from "./vendorProfileSlice";
import notificationsReducer from "./notificationSlice";

export const store = configureStore({
  reducer: {
    dashboardMenu: dashbordMenuReducer,
    auth: authReducer,
    product: productReducer, // current_product adding/editing
    allProducts: allProductsReducer, //array of all the products
    super_market: supermarketReducer, // details of super market
    orders: ordersReducer,
    vendor_profile: vendorProfileReducer, // all details of vendor/supermarket
    notifications: notificationsReducer,
  },
});
